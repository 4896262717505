import {TechRadarApi, TechRadarLoaderResponse,} from '@backstage/plugin-tech-radar';
import {ConfigApi} from "@backstage/core-plugin-api";


export class CustomTechRadarApi implements TechRadarApi {

    private config: ConfigApi

    constructor(config: ConfigApi) {
        this.config = config
    }

    async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
        const backendUrl = this.config.getString('backend.baseUrl');
        let response = await fetch(`${backendUrl}/api/custom-tech-radar/chart-data?id=${id}`)
            .then<TechRadarLoaderResponse>(res => res.json());
        return this.parseResponse(response)
    }

    private parseResponse(response: TechRadarLoaderResponse): TechRadarLoaderResponse {
        response.entries.forEach((entry) =>
            entry.timeline.forEach((snapshot) =>
                snapshot.date = new Date(snapshot.date)
            )
        )
        return response
    }
}
