import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { GitHubRepoPicker } from './gitHubRepoPicker';
import { GitHubOwnerPicker } from './gitHubOwnerPicker';

export const GitHubRepoPickerExtension = scaffolderPlugin.provide(
    createScaffolderFieldExtension({
      name: 'GitHubRepoPicker',
      component: GitHubRepoPicker
    }),
  );

export const GitHubOwnerPickerExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'GitHubOwnerPicker',
    component: GitHubOwnerPicker
  }),
);