import { useState }  from 'react';
import { useAsync } from 'react-use';
import { subDays, format } from 'date-fns';

export const statusOptions = ["", "open", "closed"];
export const priorityOptions = ["", "P1", "P2", "P3", "P4", "P5"];

export const applyCustomFilters = (fetchList: any) => {
  const [status, setStatus]:[string, any] = useState("");
  const [priority, setPriority]:[string, any] = useState("");
  const [dateRange, setDateRange]: [[Date, Date], any]  = useState(
      [subDays(new Date(), 60), new Date()] // Default date range (last 60 days)
  );
  const fromDate = format(dateRange[0], 'dd-MM-yyyy');
  const toDate = format(dateRange[1], 'dd-MM-yyyy');

  // Construct query
  let query = `createdAt>${fromDate} AND createdAt<${toDate}`;
  if (status != "") {
    query += ` AND status=${status}`;
  }
  if (priority) {
    query += ` AND priority=${priority}`;
  }

  const { value, loading, error } = useAsync(async () => {
    const response = await fetchList({ query });
    return response;
  }, [dateRange, status, priority]);

  return { 
    value, 
    loading, 
    error, 
    dateRange, 
    setDateRange, 
    status, 
    setStatus, 
    priority, 
    setPriority 
  };
};
  