import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';

export const devopsPortalPlugin = createPlugin({
  id: 'devops-portal',
  routes: {
    root: rootRouteRef,
  },
});

export const PortalHomePage = devopsPortalPlugin.provide(
  createRoutableExtension({
    name: 'PortalHomePage',
    component: () =>
      import('./components/Home').then(m => m.Home),
    mountPoint: rootRouteRef,
  }),
);

export const OnboardingPage = devopsPortalPlugin.provide(
  createRoutableExtension({
    name: 'OnboardingPage',
    component: () =>
      import('./components/Onboard').then(m => m.Onboard),
    mountPoint: rootRouteRef,
  }),
);

export const OffboardingPage = devopsPortalPlugin.provide(
  createRoutableExtension({
    name: 'OffboardingPage',
    component: () =>
      import('./components/Offboard').then(m => m.Offboard),
    mountPoint: rootRouteRef,
  }),
);

export const ApplicationPage = devopsPortalPlugin.provide(
  createRoutableExtension({
    name: 'ApplicationPage',
    component: () =>
      import('./components/Application').then(m => m.Application),
    mountPoint: rootRouteRef,
  }),
);

export const LoadingPage = devopsPortalPlugin.provide(
  createRoutableExtension({
    name: 'LoadingPage',
    component: () =>
      import('./components/Loading').then(m => m.Loading),
    mountPoint: rootRouteRef,
  }),
);

export const SelfServicePage = devopsPortalPlugin.provide(
  createRoutableExtension({
    name: 'SelfServicePage',
    component: () =>
      import('./components/SelfService').then(m => m.SelfService),
    mountPoint: rootRouteRef,
  }),
);