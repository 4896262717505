import React from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { Progress } from '@backstage/core-components';
import Alert from "@material-ui/lab/Alert";
import { IncidentsTable } from './IncidentsTable';
import { opsgenieApiRef } from '../../api';
import { Grid } from '@mui/material';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { applyCustomFilters } from '../../filters'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

export const IncidentsList = () => {
  const opsgenieApi = useApi(opsgenieApiRef);
  const { value, loading, error, dateRange, setDateRange } = applyCustomFilters(
    opsgenieApi.getIncidents.bind(opsgenieApi)
  );

  // Handle loading & error
  if (loading) {
    return <Progress />;
  } else if (error) {
    return <Alert severity = 'error'>{error.message}</Alert>;
  }
  return (
    <React.Fragment>
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item>
          <DateRangePicker
            clearIcon = {null}
            rangeDivider = "TO&nbsp;"
            onChange = {setDateRange}
            value = {dateRange}
          />
        </Grid>
      </Grid>
      <Grid container paddingTop={1}>
        <Grid item md={12} xs={12}>
        <IncidentsTable incidents={value!} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
