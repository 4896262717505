import React from 'react';

const LogoIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" 
    viewBox="85 0 595 595">
      <path 
        fill="#73DC78" 
        d="M507.3,95.5c-20.7,2.6-81.3,10.6-141.3,24.2c-81.4,18.5-156,47.6-207,94.8c-33,30.5-54.7,64-65.5,112.5
	        c-11.4,51.5-4.8,108.5,12.8,171.7c20.1-4.5,38.7-5.6,58.7-7c28.7-2,64.5-4.6,102.5-11.1c56.9-9.8,96.4-29.7,132.1-77.9
	        c31.2-42,41.1-100.9,50-142.9c12-56.1,24-93.8,35.1-119.5C491.6,124.7,496.4,113.2,507.3,95.5z M373.7,398.1
	        c-23.2,28.4-50.3,47.1-97.9,57c67.8-33.5,86.4-99,96.7-135.7c6-21.6,11.6-50.6,17.8-78c8.2-36.9,21-82.2,35.7-109.9
	        c11.9-2.1,36.1-6,39.7-6.6c-9.8,21.5-25.1,59.3-41.6,142.7C410.2,337.1,395.1,371.9,373.7,398.1z M223.6,195.8
	        c-54.2,47.3-69,91.9-76.4,132.1c-8.2,45.1-0.7,95.5,8.4,137.4c-11.7,2.7-26,5.3-31.8,6.5c-12.1-44.8-17.1-95.3-7.5-138.8
	        C131.6,264,174.5,225.8,223.6,195.8z M170.5,329.8c9.4-49.7,37-105.2,111.5-153.9c-56.3,57.9-70.3,94-79.1,137.7
	        c-7,34.8-3.8,82.1,5.6,126.1c-9.4,7.5-21,13-30.3,17.4C167.8,412.6,164.3,362.4,170.5,329.8z M228.7,422.5c-5.8-32.9-9-71-3.7-100.2
	        c10.9-61.4,47.1-113,101.5-156.5c-9.9,25.8-17.2,57.5-21.2,75.9c-8.8,41-15.8,78.8-27.9,109C263.4,385.6,249,403.8,228.7,422.5z
	        M203.4,464.9c43.6-18.9,74.8-54.9,93.2-99.4c15-36.4,21.5-70.9,30.2-112.6c3.8-18.1,15.4-68,29.2-101.1c9.4-4.1,27.3-10,41.5-13.8
	        c-14.4,31.5-25.5,75.4-33.3,111.8c-13.2,61.3-22.7,109.8-51.5,147.9C284.8,434.4,245.4,454.3,203.4,464.9z"
        />
    </svg>
  );
};

export default LogoIcon;
