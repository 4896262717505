import React from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { Progress } from '@backstage/core-components';
import Alert from "@material-ui/lab/Alert";
import { AlertsTable } from './AlertsTable';
import { opsgenieApiRef } from '../../api';
import { Grid } from '@mui/material';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { applyCustomFilters, statusOptions, priorityOptions } from '../../filters'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

export const AlertsList = () => {
  const opsgenieApi = useApi(opsgenieApiRef);
  const { 
    value, 
    loading, 
    error, 
    dateRange, 
    setDateRange,
    status, 
    setStatus, 
    priority, 
    setPriority  
  } = applyCustomFilters(
    opsgenieApi.getAlerts.bind(opsgenieApi)
  );

  if (loading) {
    return <Progress />;
  } else if (error) {
    return (
      <Alert data-testid="error-message" severity="error">
        {error.message}
      </Alert>
    );
  }
  return (
    <React.Fragment>
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item paddingRight={1}>
          <select 
            onChange={e => setPriority(e.target.value)} 
            value={priority} 
            className="react-daterange-picker__wrapper" 
            style={{"height" : "100%", "background": "transparent"}}
          >
            {priorityOptions.map((option) => (
              <option key={option} value={option}>
                {option ? option.toUpperCase() : "ALL PRIORITIES"}
              </option>
            ))}
          </select>
        </Grid>
        <Grid item paddingRight={1} paddingLeft={1}>
          <select 
            onChange={e => setStatus(e.target.value)} 
            value={status} 
            className="react-daterange-picker__wrapper" 
            style={{"height" : "100%", "background": "transparent"}}
          >
            {statusOptions.map((option) => (
              <option key={option} value={option}>
                {option ? option.toUpperCase() : "ALL STATUSES"}
              </option>
            ))}
          </select>
        </Grid>
        <Grid item paddingLeft={1}>
          <DateRangePicker 
            clearIcon = {null}
            rangeDivider = "TO&nbsp;"
            onChange = {setDateRange}
            value = {dateRange}
          />
        </Grid>
      </Grid>
      <Grid container paddingTop={1}>
        <Grid item md={12} xs={12}>
          <AlertsTable alerts={value!} />;
        </Grid>
      </Grid>
    </React.Fragment>
  )
};
