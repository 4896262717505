import './fonts.css';
import {createTheme, lightTheme, darkTheme} from "@backstage/theme";
import LightIcon from "@material-ui/icons/WbSunny";
import DarkIcon from "@material-ui/icons/WbSunny";
import {ThemeProvider} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import React from "react";
import {AppTheme} from "@backstage/core-plugin-api";

export const backstageLightTheme = createTheme({
  palette: {
    ...lightTheme.palette
  },
  fontFamily: 'Roboto',
  defaultPageTheme: 'home',
});

export const backstageDarkTheme = createTheme({
  palette: {
    ...darkTheme.palette
  },
  fontFamily: 'Roboto',
  defaultPageTheme: 'home',
});

export const appLightTheme: Partial<AppTheme> & Omit<AppTheme, 'theme'> = ({
  id: 'syngenta-light-theme',
  title: 'Light Theme',
  variant: 'light',
  icon: <LightIcon/>,
  Provider: ({children}) => (
    <ThemeProvider theme={backstageLightTheme}>
      <CssBaseline>{children}</CssBaseline>
    </ThemeProvider>
  ),
})

export const appDarkTheme: Partial<AppTheme> & Omit<AppTheme, 'theme'> = ({
  id: 'syngenta-dark-theme',
  title: 'Dark Theme',
  variant: 'dark',
  icon: <DarkIcon/>,
  Provider: ({children}) => (
    <ThemeProvider theme={backstageDarkTheme}>
      <CssBaseline>{children}</CssBaseline>
    </ThemeProvider>
  ),
})
