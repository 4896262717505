import React, { useCallback, useEffect, useState } from 'react';
import { useAsync } from 'react-use';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { TextField, CircularProgress, FormControl } from '@material-ui/core';
import Autocomplete, { createFilterOptions, AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { EntityFilterQuery } from '@backstage/catalog-client';

interface Owner {
  inputValue?: string;
  label: string;
  value: string;
}

const filter = createFilterOptions<Owner>();

export const GitHubOwnerPicker = ({
  onChange,
  schema: { title = 'GitHub Owner Picker', description = 'GitHub owner picker' },
  rawErrors,
  required,
  formData,
  idSchema,
  uiSchema,
}: FieldExtensionComponentProps<string>) => {
  const catalogApi = useApi(catalogApiRef);
  const { ownerKinds = ['User', 'Group'], allowArbitraryValues = false, outputEntity = false } = uiSchema?.['ui:options'] || {};
  const [value, setValue] = useState<Owner | null>(null);

  // Fetch owners from catalog API
  const fetchOwners = useCallback(async (): Promise<Owner[]> => {
    const users = await catalogApi.getEntities({ filter: { kind: ownerKinds } as EntityFilterQuery });
    return users.items.map((user: any) => ({
      label: user.spec?.profile?.email || user.metadata.name,
      value: outputEntity 
      ? `${user.kind.toLowerCase()}:${user.metadata.name}`
      : user.spec?.profile?.email || user.metadata.name
    }));
  }, [catalogApi, ownerKinds]);

  const { value: users = [], loading } = useAsync(fetchOwners, [fetchOwners]);

  const handleChange = useCallback((
    _event: React.ChangeEvent<{}>,
    newValue: Owner | any,
    _reason: AutocompleteChangeReason,
    _details?: any
  ) => {
    if (!newValue) {
      setValue(null);
      onChange('');
      return;
    }

    if (newValue.inputValue) {
      if (!allowArbitraryValues) {
        console.error('Arbitrary values are not allowed');
        return;
      }
      const newOption: Owner = {
        inputValue: newValue.inputValue,
        label: newValue.label,
        value: newValue.value,
      };
      setValue(newOption);
      onChange(newValue.value);
    } else {
      setValue(newValue);
      onChange(newValue.value);
    }
  }, [allowArbitraryValues, onChange]);

  useEffect(() => {
    if (value) {
      onChange(value.value);
    } else {
      onChange('');
    }
  }, [value, onChange]);

  return (
    <FormControl margin="normal" required={required} error={rawErrors?.length > 0 && !formData}>
      <Autocomplete
        disabled={users.length === 1 || loading}
        id={idSchema?.$id}
        value={value}
        loading={loading}
        onChange={handleChange}
        filterOptions={(options, params) => {
          const filteredOptions = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some(option => inputValue === option.label);

          if (inputValue && !isExisting && allowArbitraryValues) {
            filteredOptions.push({ inputValue, label: `Add "${inputValue}"`, value: inputValue });
          }

          return filteredOptions;
        }}
        clearOnBlur={allowArbitraryValues == false}
        selectOnFocus
        handleHomeEndKeys
        freeSolo
        options={users}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.label;
        }}
        renderOption={(option) => {
          return (
            <div>{option.label}</div>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={title}
            margin="dense"
            helperText={description}
            FormHelperTextProps={{ margin: 'dense', style: { marginLeft: 0 } }}
            variant="outlined"
            required={required}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress size={30} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
};
