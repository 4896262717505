import React from 'react';
import {Route} from 'react-router-dom';
import {apiDocsPlugin, ApiExplorerPage} from '@backstage/plugin-api-docs';
import {CatalogEntityPage, CatalogIndexPage, catalogPlugin,} from '@backstage/plugin-catalog';
import {CatalogImportPage, catalogImportPlugin,} from '@backstage/plugin-catalog-import';
import {ScaffolderPage, scaffolderPlugin} from '@backstage/plugin-scaffolder';
import {ScaffolderFieldExtensions} from '@backstage/plugin-scaffolder-react';
import {orgPlugin} from '@backstage/plugin-org';
import {SearchPage} from '@backstage/plugin-search';
import {TechDocsIndexPage, techdocsPlugin, TechDocsReaderPage,} from '@backstage/plugin-techdocs';
import {TechDocsAddons} from '@backstage/plugin-techdocs-react';
import {ReportIssue} from '@backstage/plugin-techdocs-module-addons-contrib';
import {UserSettingsPage} from '@backstage/plugin-user-settings';
import {apis} from './apis';
import {entityPage} from './components/catalog/EntityPage';
import {searchPage} from './components/search/SearchPage';
import {Root} from './components/Root';
import {AlertDisplay, OAuthRequestDialog, SignInPage} from '@backstage/core-components';
import {createApp} from '@backstage/app-defaults';
import {AppRouter, FlatRoutes} from '@backstage/core-app-api';
import {CatalogGraphPage} from '@backstage/plugin-catalog-graph';
import {RequirePermission} from '@backstage/plugin-permission-react';
import {catalogEntityCreatePermission} from '@backstage/plugin-catalog-common/alpha';
import {HomepageCompositionRoot, VisitListener} from '@backstage/plugin-home';
import {HomePage} from './components/home/HomePage';
import {configApiRef, githubAuthApiRef, useApi, IdentityApi, discoveryApiRef, microsoftAuthApiRef, oktaAuthApiRef} from '@backstage/core-plugin-api';
import {OpsgeniePage} from '@internal/backstage-plugin-syngenta-opsgenie';
import {QetaPage} from '@drodil/backstage-plugin-qeta';
import {
    ApplicationPage,
    LoadingPage,
    OffboardingPage,
    OnboardingPage,
    PortalHomePage,
    SelfServicePage
} from 'plugin-devops-portal/src/plugin';
import {appDarkTheme, appLightTheme} from './SyngentaTheme';
import {EntityValidationPage} from '@backstage/plugin-entity-validation';
import {AnnouncementsPage} from '@procore-oss/backstage-plugin-announcements';
import { setTokenCookie } from './cookieAuth';
import {CustomTechRadarPage} from '@internal/plugin-custom-tech-radar';
import {GitHubRepoPickerExtension, GitHubOwnerPickerExtension} from './components/scaffolder/customScaffolderExtensions';


const providers = [
  {
    id: 'github-auth-provider',
    title: 'GitHub',
    message: 'Sign in using GitHub',
    apiRef: githubAuthApiRef,
  },
  {
    id: 'microsoft-auth-provider',
    title: 'Microsoft SSO',
    message: 'Sign in using Microsoft SSO',
    apiRef: microsoftAuthApiRef,
  },
  {
    id: 'okta-auth-provider',
    title: 'Okta SSO',
    message: 'Sign in using Okta SSO',
    apiRef: oktaAuthApiRef,
  },
];

const app = createApp({
  apis,
  themes: [appLightTheme, appDarkTheme],
  components: {
    SignInPage: props => {
      const discoveryApi = useApi(discoveryApiRef);
      return (
        <SignInPage
          {...props}
          auto
          providers={
            useApi(configApiRef).getString('auth.environment') === 'development'
              ? ['guest']
              : [...providers]
          }
          onSignInSuccess={async (identityApi: IdentityApi) => {
            setTokenCookie(
              await discoveryApi.getBaseUrl('cookie'),
              identityApi,
            );
            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
  },
  
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    {/* <Route path="/" element={<Navigate to="catalog" />} /> */}
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/opsgenie" element={<OpsgeniePage />} />
    <Route path="/entity-validation" element={<EntityValidationPage />} />
    {/* <Route path="/toolbox" element={<ToolboxPage />} /> */}
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={
        <ScaffolderPage
          groups={[
            {
              title: 'Recommended',
              filter: entity =>
                entity?.metadata?.tags?.includes('recommended') ?? false,
            },
            {
              title: 'Infrastructure',
              filter: entity =>
                entity?.metadata?.tags?.includes('infra') ?? false,
            },
            {
              title: 'Tools',
              filter: entity =>
                entity?.metadata?.tags?.includes('tools') ?? false,
            },
            {
              title: 'Components',
              filter: entity =>
                entity?.metadata?.tags?.includes('component') ?? false,
            },
          ]}
        />
      }
    >
      <ScaffolderFieldExtensions>
        <GitHubRepoPickerExtension />
        <GitHubOwnerPickerExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/qeta" element={<QetaPage title="Questions" />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/portal-home" element={<PortalHomePage />} />
    <Route path="/onboarding" element={<OnboardingPage />} />
    <Route path="/offboarding" element={<OffboardingPage />} />
    <Route path="/application" element={<ApplicationPage />} />
    <Route path="/loading" element={<LoadingPage />} />
    <Route path="/self-service" element={<SelfServicePage />} />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route path="/tech-radar" element={<CustomTechRadarPage  width={1500} height={800} id={"main-tech-radar"}/>} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <VisitListener />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
