import React from 'react';
import SyngentaIcon from './images/logo.svg';
import ConfluenceIcon from './images/confluence.svg';
import CircleIcon from './images/circleci.svg';

export const SyngentaLogo = () => {
  return <img src={SyngentaIcon} alt="Syngenta Digital"/>;
};

export const ConfluenceLogoIcon = () => {
  return <img src={ConfluenceIcon} alt="Confluence"/>;
};
  
export const CircleLogoIcon = () => {
  return <img src={CircleIcon} alt="Circle CI"/>;
};