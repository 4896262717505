import {
  HomePageToolkit,
  HomePageCompanyLogo,
  HomePageStarredEntities,
  TemplateBackstageLogo,
  HomePageTopVisited,
  HomePageRecentlyVisited
} from '@backstage/plugin-home';
import React, { ComponentType } from 'react';
import { wrapInTestApp, TestApiProvider } from '@backstage/test-utils';
import { Content, Page } from '@backstage/core-components';
import { entityRouteRef } from '@backstage/plugin-catalog-react';
import { HomePageSearchBar} from '@backstage/plugin-search';
import { searchApiRef, SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import { ConfluenceLogoIcon, CircleLogoIcon} from '../../Images';
import { AnnouncementsCard , NewAnnouncementBanner} from '@procore-oss/backstage-plugin-announcements';


export default {
  title: 'Plugins/Home/Templates',
  decorators: [
    (Story: ComponentType<{}>) =>
      wrapInTestApp(
        <>
          <TestApiProvider
            apis={[
              [searchApiRef, { query: () => Promise.resolve({ results: [] }) }],
            ]}
          >
            <Story />
          </TestApiProvider>
        </>,
        {
          mountedRoutes: {
            '/catalog/:namespace/:kind/:name': entityRouteRef,
          },
        },
      ),
  ],
};

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 70,
  },
  path: {
    fill: '#73DC78',
  },
}));

export const HomePage = () => {
  const { svg, path, container } = useLogoStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
        <Grid item md={12}>
            <NewAnnouncementBanner />
          </Grid>
          
          <Grid container justifyContent="center" spacing={6}>
            <HomePageCompanyLogo
              className={container}
              logo={<TemplateBackstageLogo classes={{ svg, path }} />}
            />
            <Grid container item xs={12} alignItems="center" direction="row">
              <HomePageSearchBar
                placeholder="Search"
              />
            </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12} md={6}>
                  <HomePageTopVisited />
                </Grid>
                <Grid item xs={12} md={6}>
                  <HomePageRecentlyVisited />
                </Grid>
              </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit
                  tools={[{
                    url: 'https://digitial-product-engineering.atlassian.net/wiki/home',
                    label: 'Confluence',
                    icon: <ConfluenceLogoIcon />,
                  }, {
                    url: 'https://app.circleci.com/pipelines/github/syngenta-digital',
                    label: 'CircleCI',
                    icon: <CircleLogoIcon />,
                  }]}
                />
              </Grid>
              <Grid item md={6}>
                  <AnnouncementsCard max={3} />
                </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider> 
  );
};