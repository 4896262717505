import {ScmAuth, ScmIntegrationsApi, scmIntegrationsApiRef,} from '@backstage/integration-react';

import {
    AnyApiFactory,
    configApiRef,
    createApiFactory,
    analyticsApiRef,
    identityApiRef,
    discoveryApiRef,
    githubAuthApiRef,
    oauthRequestApiRef,
} from '@backstage/core-plugin-api';
import { GoogleAnalytics4 } from '@backstage/plugin-analytics-module-ga4';
import { GithubAuth } from '@backstage/core-app-api';

export const apis: AnyApiFactory[] = [
    // Instantiate and register the GA Analytics API Implementation.
    createApiFactory({
        api: analyticsApiRef,
        deps: { configApi: configApiRef, identityApi: identityApiRef },
        factory: ({ configApi, identityApi }) =>
          GoogleAnalytics4.fromConfig(configApi, {
            identityApi,
        }),
    }),
    createApiFactory({
        api: scmIntegrationsApiRef,
        deps: {configApi: configApiRef},
        factory: ({configApi}) => ScmIntegrationsApi.fromConfig(configApi),
    }),
    ScmAuth.createDefaultApiFactory(),
    createApiFactory({
        api: githubAuthApiRef,
        deps: {
            configApi: configApiRef,
            discoveryApi: discoveryApiRef,
            oauthRequestApi: oauthRequestApiRef,
        },
        factory: ({discoveryApi, oauthRequestApi, configApi}) =>
            GithubAuth.create({
                discoveryApi,
                oauthRequestApi,
                defaultScopes: ['read:user'],
                environment: configApi.getString('auth.environment'),
            }),
    }),
];
