import React, { useCallback, useEffect, useRef } from 'react';
import { useAsync } from 'react-use';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { TextField } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useApi, configApiRef, identityApiRef } from '@backstage/core-plugin-api';
import { getGitHubRepos } from '../hooks/gitHubRepoPickerHook';

export const GitHubRepoPicker = ({
    onChange,
    schema: { title = 'Syngenta Digital GitHub Repos', description = 'syngenta-digital repo names' },
    rawErrors,
    required,
    formData,
    idSchema,
  }: FieldExtensionComponentProps<string>) => {

  const baseUrl: string = useRef(useApi(configApiRef).getString('backend.baseUrl')).current;
  const identity = useRef(useApi(identityApiRef).getCredentials());

  const { value: repos, loading } = useAsync(async () =>
    getGitHubRepos(baseUrl, (await identity.current).token)
  );

  const onSelect = useCallback(
  (_: any, value: string | null) => {
    onChange(value ?? undefined);
  }, [onChange],
  );

  useEffect(() => {
    if (repos?.length === 1) {
      onChange(repos[0]);
    }
  }, [repos, onChange]);

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <Autocomplete
        disabled={repos?.length === 1 || loading}
        id={idSchema?.$id}
        value={(formData as string) || ''}
        loading={loading}
        onChange={onSelect}
        options={repos || []}
        autoSelect
        renderInput={params => (
        <TextField
            {...params}
            label={title}
            margin="dense"
            helperText={description}
            FormHelperTextProps={{ margin: 'dense', style: { marginLeft: 0 } }}
            variant="outlined"
            required={required}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress size={30} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
};