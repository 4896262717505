import {configApiRef, createApiFactory, createPlugin, createRoutableExtension} from '@backstage/core-plugin-api';

import {rootRouteRef} from './routes';
import {techRadarApiRef} from "@backstage/plugin-tech-radar";
import {CustomTechRadarApi} from "./components/apis/customTechRadarApi";

export const customTechRadarPlugin = createPlugin({
    id: 'custom-tech-radar',
    apis: [
        createApiFactory({
            api: techRadarApiRef,
            deps: {configApi: configApiRef},
            factory: ({configApi}) => new CustomTechRadarApi(configApi),
        })],
    routes: {
        root: rootRouteRef,
    },
});

export const CustomTechRadarPage = customTechRadarPlugin.provide(
    createRoutableExtension({
        name: 'CustomTechRadarPage',
        component: () =>
            import('./components/CustomTechRadar').then(m => m.CustomTechRadarPage),
        mountPoint: rootRouteRef,
    }),
);
